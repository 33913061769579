<template>
  <div class="bg-light">
    <div
      :class="containerClass"
      v-if="!preEnrolledSuccessfull && !preEnrolledUnsuccessfull"
      class="offset-lg-2 col-lg-8 col-md-12 bg-white border shadowed"
      style="
        -webkit-box-shadow: 0px 0px 19px -6px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 19px -6px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 0px 19px -6px rgba(0, 0, 0, 0.75);
      "
    >
      <div class="d-flex justify-content-center mt-2 pt-2">
        <b-skeleton-wrapper :loading="isClassLoading">
          <template #loading>
            <b-skeleton-img width="160px" height="120px" no-aspect></b-skeleton-img>
          </template>
          <b-img :src="get(getCurrentSchool, 'logo_url')" height="110" />
        </b-skeleton-wrapper>
      </div>

      <b-skeleton-wrapper :loading="isClassLoading">
        <template #loading>
          <div class="d-flex justify-content-center mt-3">
            <b-skeleton width="60%"></b-skeleton>
          </div>
        </template>
        <div class="d-flex justify-content-center mt-2 text-center">
          <h3 class="mb-0 font-weight-bolder" v-text="`${get(classSch, 'program.title')} Pre Enrollment`" />
        </div>
      </b-skeleton-wrapper>

      <div class="mx-3 mb-4 mt-4 text-center">
        <b-skeleton-wrapper :loading="isClassLoading">
          <template #loading>
            <p class="d-flex justify-content-center">
              <b-skeleton width="90%"></b-skeleton>
            </p>
          </template>

          <span style="font-size: 0.9125rem">
            <span>
              This program requires pre-enrollment verification, we will send you a registration link once your
              verification is completed. Please make sure to contact us to make sure all of your questions are answered
            </span>
          </span>
        </b-skeleton-wrapper>
      </div>

      <b-form class="col-md-12 px-0 page-section pt-0 pb-5" @submit.prevent="onSubmit">
        <div class="card">
          <div class="card-header bg-light">
            <h5 class="my-0">Class Information</h5>
          </div>
          <div class="card-body">
            <div class="flex">
              <b-skeleton-wrapper :loading="isClassLoading">
                <template #loading>
                  <p class="lead text-white-50">
                    <b-skeleton width="70%"></b-skeleton>
                  </p>
                  <p class="lead text-white-50">
                    <b-skeleton width="60%"></b-skeleton>
                  </p>
                  <p class="lead text-white-50">
                    <b-skeleton width="50%"></b-skeleton>
                  </p>
                  <p class="lead text-white-50">
                    <b-skeleton width="40%"></b-skeleton>
                  </p>
                </template>
                <div class="icon-align mb-2" v-if="classSch.class_type === CLASS_SCHEDULE_TYPES.SCHEDULED">
                  <i class="fas fa-calendar-alt icon-16pt mr-1"></i><b class="mr-1 form-label">Start/End:</b>
                  {{ formatDateSimple(classSch.start_date) }} to
                  {{ formatDateSimple(classSch.end_date) }}
                </div>
                <div class="icon-align mb-2">
                  <i class="fas fa-map-marker-alt icon-16pt mr-1"></i> <b class="mr-1 form-label">Location:</b>
                  <a class="text-underline text-primary">{{ fullClassLocation }}</a>
                </div>

                <div class="icon-align mb-2">
                  <i class="fas fa-calendar-week icon-16pt mr-1"></i> <b class="mr-1 form-label">Total Weeks:</b>
                  {{ $n(classSch.total_weeks, 'singleDecimal', 'en-US') }}
                </div>
                <div class="icon-align mb-2">
                  <i class="fas fa-clock icon-16pt mr-1"></i> <b class="mr-1 form-label">Total Hours:</b>
                  {{ $n(classSch.total_hours, 'singleDecimal', 'en-US') }}
                </div>
                <div class="icon-align mb-2" v-if="classSch.orientation_date">
                  <i class="fas fa-calendar-day icon-16pt mr-1"></i> <b class="mr-1 form-label">Orientation Date:</b>
                  {{ formatDateSimple(classSch.orientation_date) }}
                </div>
                <div class="icon-align mb-2">
                  <i class="fas fa-clipboard-list icon-16pt mr-1"></i>
                  <b class="mr-1 form-label">Schedule:</b>
                  {{
                    classSch.class_type === CLASS_SCHEDULE_TYPES.SCHEDULED
                      ? $t(`scheduleTypes.${classSch.schedule_type}`)
                      : 'Self-Paced'
                  }}
                </div>
              </b-skeleton-wrapper>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header bg-light">
            <h5 class="my-0">Student Information</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <b-form-group label="First Name*" label-for="firstname" label-class="form-label" class="col-md-6">
                <b-form-input
                  id="firstname"
                  :placeholder="$t('userMsgs.firstName')"
                  v-model.trim="user.firstName"
                  :state="!$v.user.firstName.required && $v.user.firstName.$dirty ? false : null"
                />
                <b-form-invalid-feedback v-if="!$v.user.firstName.required"
                  >This field is required.</b-form-invalid-feedback
                >
              </b-form-group>

              <b-form-group label="Last Name*" label-for="lastname" label-class="form-label" class="col-md-6">
                <b-form-input
                  id="lastname"
                  :placeholder="$t('userMsgs.lastName')"
                  v-model.trim="user.lastName"
                  :state="!$v.user.lastName.required && $v.user.lastName.$dirty ? false : null"
                />
                <b-form-invalid-feedback v-if="!$v.user.lastName.required"
                  >This field is required.</b-form-invalid-feedback
                >
              </b-form-group>
            </div>

            <b-form-group label="Email Address*" label-for="email" label-class="form-label">
              <b-form-input
                :disabled="$route.query.regId ? true : false"
                id="email"
                :placeholder="$t('authMsgs.emailAddr')"
                type="email"
                v-model.trim="user.email"
                :state="(!$v.user.email.required || !$v.user.email.email) && $v.user.$dirty ? false : null"
              />
              <b-form-invalid-feedback v-if="!$v.user.email.email">
                Invalid email. Please make sure that it's in correct format i.e.
                test@example.com.</b-form-invalid-feedback
              >
              <b-form-invalid-feedback v-else>This field is required.</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Phone*" label-for="phone" label-class="form-label">
              <b-form-input
                id="phone"
                placeholder="(888) 689 - 1235"
                v-model="user.phone"
                v-mask="'(###) ### - ####'"
                :state="
                  (!$v.user.phone.required || !$v.user.phone.isCorrectFormat) && $v.user.phone.$dirty ? false : null
                "
              />
              <b-form-invalid-feedback v-if="!$v.user.phone.required">
                This field is required.
              </b-form-invalid-feedback>
              <b-form-invalid-feedback v-else>
                Invalid phone number. Please make sure that it's in correct format i.e. (XXX) XXX - XXXX.
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Birth Date*" label-for="gradDate" label-class="form-label">
              <date-picker
                v-model="student.birthday"
                type="date"
                format="MM/DD/YYYY"
                value-type="YYYY-MM-DD"
                style="width: 100%"
                id="birthday"
                lang="en"
                placeholder="MM/DD/YYYY"
                :input-class="`mx-input ${
                  !$v.student.birthday.required && $v.student.birthday.$dirty ? 'form-control is-invalid' : ''
                }`"
              ></date-picker>

              <b-form-invalid-feedback v-if="!$v.student.birthday.required && $v.student.birthday.$dirty"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group v-if="responseData.student_ssn !== STATIC_FIELD_TYPE.DISABLED">
              <label for="student_ssn" class="form-label">
                {{ responseData.student_ssn === STATIC_FIELD_TYPE.REQUIRED ? 'SSN*' : 'SSN' }}</label
              >
              <md-icon
                class="mb-1"
                style="font-size: 16px; color: grey"
                v-b-popover.hover.right
                :title="isFourDigitSSN ? 'Please enter last 4 digits of your SSN.' : 'Please enter your complete SSN.'"
                >info</md-icon
              >

              <div style="position: relative; display: flex; align-items: center; justify-content: flex-end">
                <b-input-group :prepend="isFourDigitSSN ? '***-**-' : ''">
                  <b-form-input
                    id="student_ssn"
                    :type="ssnPassType"
                    :placeholder="isFourDigitSSN ? '****' : '***-**-****'"
                    :v-mask="isFourDigitSSN ? '####' : '###-##-####'"
                    v-model="student.student_ssn"
                    :maxlength="isFourDigitSSN ? 4 : 9"
                    :state="
                      (!$v.student.student_ssn.required || !$v.student.student_ssn.length) &&
                      $v.student.student_ssn.$dirty
                        ? false
                        : null
                    "
                  />
                  <b-form-invalid-feedback v-if="!$v.student.student_ssn.required"
                    >This field is required.</b-form-invalid-feedback
                  >
                  <b-form-invalid-feedback v-else-if="!$v.student.student_ssn.length"
                    >Please enter complete ssn.</b-form-invalid-feedback
                  >
                </b-input-group>

                <span style="position: absolute; margin-right: 6px" @click="changeField()" class="ml-1 my-auto">
                  <md-icon style="cursor: pointer; font-size: 21px; color: grey">{{
                    visibility ? 'visibility' : 'visibility_off'
                  }}</md-icon>
                </span>
              </div>
            </b-form-group>
            <b-form-group
              :label="`Language Preference(s)${
                responseData.lang_preferences === STATIC_FIELD_TYPE.REQUIRED ? '*' : ''
              }`"
              label-for="lang_preferences"
              label-class="form-label"
              v-if="responseData.lang_preferences !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="lang_preferences"
                class="form-control v-select-custom"
                label="name"
                v-model="student.lang_preferences"
                :reduce="(item) => item.value"
                placeholder="Select Preferred Language"
                :options="langOptions"
                :class="
                  !$v.student.lang_preferences.required && $v.student.lang_preferences.$dirty
                    ? 'form-control is-invalid'
                    : ''
                "
                multiple
              >
              </v-select>
              <b-form-invalid-feedback
                :state="!$v.student.lang_preferences.required && $v.student.lang_preferences.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              :label="responseData.gender === STATIC_FIELD_TYPE.REQUIRED ? 'Gender*' : 'Gender'"
              label-for="gender"
              label-class="form-label"
              v-if="responseData.gender !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="gender"
                class="form-control v-select-custom"
                label="text"
                v-model="user.gender"
                :reduce="(item) => item.value"
                placeholder="Select your gender"
                :options="genderOptions"
                :class="!$v.user.gender.required && $v.user.gender.$dirty ? 'form-control is-invalid' : ''"
              >
              </v-select>
              <b-form-invalid-feedback :state="!$v.user.gender.required && $v.user.gender.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              :label="responseData.ethnicity === STATIC_FIELD_TYPE.REQUIRED ? 'Ethnicity*' : 'Ethnicity'"
              label-for="ethnicity"
              label-class="form-label"
              v-if="responseData.ethnicity !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="ethnicity"
                class="form-control v-select-custom"
                label="text"
                v-model="ethnicity"
                :reduce="(r) => r.value"
                placeholder="Select an Option"
                :options="ethnicityOptions"
                :class="!$v.ethnicity.required && $v.ethnicity.$dirty ? 'form-control is-invalid' : ''"
              >
              </v-select>
              <b-form-invalid-feedback :state="!$v.ethnicity.required && $v.ethnicity.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
              <b-form-input
                id="ethnicity_other"
                class="mt-1"
                placeholder="Please specify"
                v-model="ethnicity_other"
                required
                v-if="ethnicity === 'other'"
                :state="!$v.ethnicity_other.required && $v.ethnicity_other.$dirty ? false : null"
              />

              <b-form-invalid-feedback
                v-if="ethnicity === 'other'"
                :state="
                  (!$v.ethnicity.required && $v.ethnicity.$dirty) ||
                  (!$v.ethnicity_other.required && $v.ethnicity_other.$dirty)
                    ? false
                    : null
                "
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <!-- Address Fields -->
            <b-form-group
              :label="responseData.address === STATIC_FIELD_TYPE.REQUIRED ? 'Address*' : 'Address'"
              label-for="address"
              label-class="form-label"
              v-if="responseData.address !== STATIC_FIELD_TYPE.DISABLED"
            >
              <b-form-input
                id="address"
                :placeholder="$t('addressMsgs.address')"
                v-model="student.address"
                :state="!$v.student.address.required && $v.student.address.$dirty ? false : null"
              />
              <b-form-invalid-feedback :state="!$v.student.address.required && $v.student.address.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <div class="row">
              <b-form-group
                :label="responseData.city === STATIC_FIELD_TYPE.REQUIRED ? 'City*' : 'City'"
                label-for="city"
                label-class="form-label"
                class="col-md-6"
                v-if="responseData.city !== STATIC_FIELD_TYPE.DISABLED"
              >
                <b-form-input
                  id="city"
                  :placeholder="$t('addressMsgs.city')"
                  v-model="student.city"
                  :state="!$v.student.city.required && $v.student.city.$dirty ? false : null"
                />
                <b-form-invalid-feedback :state="!$v.student.city.required && $v.student.city.$dirty ? false : null"
                  >This field is required.</b-form-invalid-feedback
                >
              </b-form-group>
              <b-form-group
                :label="responseData.state === STATIC_FIELD_TYPE.REQUIRED ? 'State*' : 'State'"
                label-for="state"
                label-class="form-label"
                class="col-md-6"
                v-if="responseData.state !== STATIC_FIELD_TYPE.DISABLED"
              >
                <v-select
                  id="state"
                  class="form-control v-select-custom"
                  label="text"
                  v-model="student.state"
                  :reduce="(state) => state.value"
                  placeholder="Select State"
                  :options="stateOptions"
                  :class="!$v.student.state.required && $v.student.state.$dirty ? 'form-control is-invalid' : ''"
                >
                </v-select>
                <b-form-invalid-feedback :state="!$v.student.state.required && $v.student.state.$dirty ? false : null"
                  >This field is required.</b-form-invalid-feedback
                >
              </b-form-group>
            </div>

            <div class="row">
              <b-form-group
                :label="responseData.zipcode === STATIC_FIELD_TYPE.REQUIRED ? 'Zipcode*' : 'Zipcode'"
                label-for="zipcode"
                label-class="form-label"
                class="col-md-6"
                v-if="responseData.zipcode !== STATIC_FIELD_TYPE.DISABLED"
              >
                <b-form-input
                  id="zipcode"
                  :placeholder="$t('addressMsgs.zipcode')"
                  v-model="student.zipcode"
                  :state="!$v.student.zipcode.required && $v.student.zipcode.$dirty ? false : null"
                />
                <b-form-invalid-feedback
                  :state="!$v.student.zipcode.required && $v.student.zipcode.$dirty ? false : null"
                  >This field is required.</b-form-invalid-feedback
                >
              </b-form-group>

              <b-form-group
                :label="responseData.country === STATIC_FIELD_TYPE.REQUIRED ? 'Country*' : 'Country'"
                label-for="country"
                label-class="form-label"
                class="col-md-6"
                v-if="responseData.country !== STATIC_FIELD_TYPE.DISABLED"
              >
                <v-select
                  id="country"
                  class="form-control v-select-custom"
                  label="text"
                  v-model="student.country"
                  disabled
                  :reduce="(country) => country.value"
                  :placeholder="$t('addressMsgs.countryPlaceholder')"
                  :options="countryOptions"
                  :class="!$v.student.country.required && $v.student.country.$dirty ? 'form-control is-invalid' : ''"
                >
                </v-select>
                <b-form-invalid-feedback
                  :state="!$v.student.country.required && $v.student.country.$dirty ? false : null"
                  >This field is required.</b-form-invalid-feedback
                >
              </b-form-group>
            </div>

            <b-form-group
              :label="`Why are you interested in registering for the ${get(classSch, 'program.title', '')} program?${
                responseData.registration_reason === STATIC_FIELD_TYPE.REQUIRED ? '*' : ''
              }`"
              label-for="registration_reason"
              label-class="form-label"
              v-if="responseData.registration_reason !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="registration_reason"
                class="form-control v-select-custom"
                label="text"
                v-model="registration_reason"
                :reduce="(r) => r.value"
                placeholder="Select the reason for registration"
                :options="allReasons"
                :class="
                  !$v.registration_reason.required && $v.registration_reason.$dirty ? 'form-control is-invalid' : ''
                "
              >
              </v-select>
              <b-form-invalid-feedback
                :state="!$v.registration_reason.required && $v.registration_reason.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
              <b-form-input
                id="other_registration_reason"
                class="mt-1"
                placeholder="Please specify"
                v-model="other_registration_reason"
                required
                v-if="registration_reason === 'other'"
                :state="!$v.other_registration_reason.required && $v.other_registration_reason.$dirty ? false : null"
              />

              <b-form-invalid-feedback
                v-if="registration_reason === 'other'"
                :state="
                  (!$v.registration_reason.required && $v.registration_reason.$dirty) ||
                  (!$v.other_registration_reason.required && $v.other_registration_reason.$dirty)
                    ? false
                    : null
                "
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              :label="`How did you hear about our ${get(classSch, 'program.title', '')} program?${
                responseData.reference === STATIC_FIELD_TYPE.REQUIRED ? '*' : ''
              }`"
              label-for="reference"
              label-class="form-label"
              v-if="responseData.reference !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="reference"
                class="form-control v-select-custom"
                label="text"
                v-model="reference"
                :reduce="(r) => r.value"
                placeholder="Select reference"
                :options="allReferals"
                :class="!$v.reference.required && $v.reference.$dirty ? 'form-control is-invalid' : ''"
              >
              </v-select>
              <b-form-invalid-feedback :state="!$v.reference.required && $v.reference.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
              <b-form-input
                id="other_reference"
                class="mt-1"
                placeholder="Please specify"
                v-model="other_reference"
                required
                v-if="reference === 'other'"
                :state="!$v.other_reference.required && $v.other_reference.$dirty ? false : null"
              />

              <b-form-invalid-feedback
                v-if="reference === 'other'"
                :state="
                  (!$v.reference.required && $v.reference.$dirty) ||
                  (!$v.other_reference.required && $v.other_reference.$dirty)
                    ? false
                    : null
                "
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>
          </div>
        </div>

        <!-- Student Profile Info -->

        <div class="card">
          <div class="card-header bg-light">
            <h5 class="my-0">Career Information</h5>
          </div>
          <div class="card-body">
            <b-form-group
              label="Have you been certified before?*"
              label-for="certified_before"
              label-class="form-label"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-radio-group
                id="certified_before"
                v-model="student.certified_before"
                :options="yesNoOptions"
                :aria-describedby="ariaDescribedby"
                required
                :state="!$v.student.certified_before.required && $v.student.certified_before.$dirty ? false : null"
              ></b-form-radio-group>
              <b-form-invalid-feedback
                :state="!$v.student.certified_before.required && $v.student.certified_before.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              :label="`Current Occupation${responseData.occupation === STATIC_FIELD_TYPE.REQUIRED ? '*' : ''}`"
              label-for="occupation"
              label-class="form-label"
              v-if="responseData.occupation !== STATIC_FIELD_TYPE.DISABLED"
            >
              <b-form-input
                id="occupation"
                placeholder="Please enter your occupation (if any)"
                v-model="student.occupation"
                :state="!$v.student.occupation.required && $v.student.occupation.$dirty ? false : null"
              />

              <b-form-invalid-feedback
                :state="!$v.student.occupation.required && $v.student.occupation.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              label="Are you interested in receiving new opportunities from employers we partner with?*"
              label-for="career_service_interest"
              label-class="form-label"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-radio-group
                id="career_service_interest"
                v-model="student.career_service_interest"
                :options="yesNoOptions"
                :aria-describedby="ariaDescribedby"
                required
                :state="
                  !$v.student.career_service_interest.required && $v.student.career_service_interest.$dirty
                    ? false
                    : null
                "
              ></b-form-radio-group>
              <b-form-invalid-feedback
                :state="
                  !$v.student.career_service_interest.required && $v.student.career_service_interest.$dirty
                    ? false
                    : null
                "
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <!-- Work Schedule Fields -->
            <b-form-group
              :label="`What type(s) of facilities do you prefer to work at?${
                responseData.interested_facility_types === STATIC_FIELD_TYPE.REQUIRED ? '*' : ''
              }`"
              label-for="interested_facility_types"
              label-class="form-label"
              v-if="responseData.interested_facility_types !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="interested_facility_types"
                class="form-control v-select-custom"
                label="text"
                v-model="interested_facility_types"
                :reduce="(item) => item.value"
                placeholder="Select preferred facility type(s)"
                :options="facilityTypeOptions"
                multiple
                :class="
                  !$v.interested_facility_types.required && $v.interested_facility_types.$dirty
                    ? 'form-control is-invalid'
                    : ''
                "
              >
              </v-select>
              <b-form-invalid-feedback
                :state="!$v.interested_facility_types.required && $v.interested_facility_types.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group
              :label="`What type(s) of schedule do you prefer to work?${
                responseData.desired_work_type === STATIC_FIELD_TYPE.REQUIRED ? '*' : ''
              }`"
              label-for="desired_work_type"
              label-class="form-label"
              v-if="responseData.desired_work_type !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="desired_work_type"
                class="form-control v-select-custom"
                label="text"
                v-model="student.desired_work_type"
                :reduce="(item) => item.value"
                placeholder="Select preferred work schedule(s)"
                :options="workTypeOptions"
                multiple
                :class="
                  !$v.student.desired_work_type.required && $v.student.desired_work_type.$dirty
                    ? 'form-control is-invalid'
                    : ''
                "
              >
              </v-select>
              <b-form-invalid-feedback
                :state="!$v.student.desired_work_type.required && $v.student.desired_work_type.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              :label="`What time(s) do you prefer to work?${
                responseData.desired_work_time === STATIC_FIELD_TYPE.REQUIRED ? '*' : ''
              }`"
              label-for="desired_work_time"
              label-class="form-label"
              v-if="responseData.desired_work_time !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="desired_work_time"
                class="form-control v-select-custom"
                label="text"
                v-model="student.desired_work_time"
                :reduce="(item) => item.value"
                placeholder="Select preferred work time(s)"
                :options="workTimeOptions"
                multiple
                :class="
                  !$v.student.desired_work_time.required && $v.student.desired_work_time.$dirty
                    ? 'form-control is-invalid'
                    : ''
                "
              >
              </v-select>
              <b-form-invalid-feedback
                :state="!$v.student.desired_work_time.required && $v.student.desired_work_time.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              :label="`What day(s) do you prefer to work?${
                responseData.desired_work_day === STATIC_FIELD_TYPE.REQUIRED ? '*' : ''
              }`"
              label-for="desired_work_day"
              label-class="form-label"
              v-if="responseData.desired_work_day !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="desired_work_day"
                class="form-control v-select-custom"
                label="text"
                v-model="student.desired_work_day"
                :reduce="(item) => item.value"
                placeholder="Select preferred work day(s)"
                :options="workDayOptions"
                multiple
                :class="
                  !$v.student.desired_work_day.required && $v.student.desired_work_day.$dirty
                    ? 'form-control is-invalid'
                    : ''
                "
              >
              </v-select>
              <b-form-invalid-feedback
                :state="!$v.student.desired_work_day.required && $v.student.desired_work_day.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              :label="`What distance are you willing to travel for work?${
                responseData.work_travel === STATIC_FIELD_TYPE.REQUIRED ? '*' : ''
              }`"
              label-for="work_travel"
              label-class="form-label"
              v-if="responseData.work_travel !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="work_travel"
                class="form-control v-select-custom"
                label="text"
                v-model="student.work_travel"
                :reduce="(item) => item.value"
                placeholder="Select preferred mile(s)"
                :options="workTravelOptions"
                :class="
                  !$v.student.work_travel.required && $v.student.work_travel.$dirty ? 'form-control is-invalid' : ''
                "
              >
              </v-select>
              <b-form-invalid-feedback
                :state="!$v.student.work_travel.required && $v.student.work_travel.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              label="Resume (Optional)"
              label-for="resume"
              label-class="form-label"
              class="row-align-items-center"
              v-if="responseData.resume_url !== STATIC_FIELD_TYPE.DISABLED"
            >
              <b-media class="align-items-center" vertical-align="center">
                <b-form-file
                  id="resume"
                  placeholder="Select Resume File"
                  :browse-text="$t('generalMsgs.browse')"
                  v-model="selectedUploadFile.resume"
                  @input="uploadFile('resume', 'documents', 'resume_url')"
                  :disabled="isUploading.resume"
                  accept=".pdf, .docx, .doc"
                  :state="!$v.student.resume_url.required && $v.student.resume_url.$dirty ? false : null"
                />
              </b-media>
              <b-form-invalid-feedback
                :state="!$v.student.resume_url.required && $v.student.resume_url.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group
              label="Career Profile Image (Optional)"
              label-for="avatar"
              label-class="form-label"
              class="row-align-items-center"
            >
              <b-media class="align-items-center" vertical-align="center">
                <user-avatar
                  slot="aside"
                  rounded="lg"
                  size="lg"
                  :isLoading="isUploading.avatar"
                  :user="user"
                  url-field="avatarUrl"
                  first-name-field="firstName"
                  last-name-field="lastName"
                >
                </user-avatar>

                <image-uploader
                  @image="(e) => setImage(e, 'avatar', 'images')"
                  :isUploading="isUploading.avatar"
                  :isRequired="false"
                  placeholder="Select image for career profile"
                />
              </b-media>
            </b-form-group>
          </div>
        </div>

        <div class="card" v-for="section in sections" :key="`sec-${section.id}`">
          <div class="card-header bg-light">
            <h5 class="my-0">{{ section.title }}</h5>
          </div>
          <div class="card-body">
            <b-form-group
              :label="ques.is_required ? `${get(ques, 'question.title')}*` : get(ques, 'question.title')"
              :label-for="`section-${section.id}-question-${ques.id}`"
              label-class="form-label"
              v-slot="{ ariaDescribedby }"
              v-for="ques in section.sectionquestion_set"
              :key="`sec-que-${ques.id}`"
            >
              <b-form-radio-group
                v-if="ques.question.type === QUES_TYPES.BOOLEAN"
                :id="`section-${section.id}-question-${ques.id}`"
                v-model="customSectionData[`section-${section.id}`][`question-${ques.id}`]"
                :options="get(ques.question, 'extra.options', [])"
                :aria-describedby="ariaDescribedby"
                :state="
                  !get($v, `customSectionData.section-${section.id}.question-${ques.id}.required`, true) &&
                  get($v, `customSectionData.section-${section.id}.question-${ques.id}.$dirty`, false)
                    ? false
                    : null
                "
                :required="ques.is_required"
              ></b-form-radio-group>

              <b-form-input
                :id="`section-${section.id}-question-${ques.id}`"
                v-if="ques.question.type === QUES_TYPES.SHORT_TEXT"
                :placeholder="get(ques.question, 'placeholder', 'Please write your answer here.')"
                v-model="customSectionData[`section-${section.id}`][`question-${ques.id}`]"
                :state="
                  !get($v, `customSectionData.section-${section.id}.question-${ques.id}.required`, true) &&
                  get($v, `customSectionData.section-${section.id}.question-${ques.id}.$dirty`, false)
                    ? false
                    : null
                "
                :required="ques.is_required"
              />
              <b-form-textarea
                v-if="ques.question.type === QUES_TYPES.LONG_TEXT"
                :id="`section-${section.id}-question-${ques.id}`"
                class="form-control"
                rows="10"
                :placeholder="get(ques.question, 'placeholder', 'Please write your answer here.')"
                v-model="customSectionData[`section-${section.id}`][`question-${ques.id}`]"
                :state="
                  !get($v, `customSectionData.section-${section.id}.question-${ques.id}.required`, true) &&
                  get($v, `customSectionData.section-${section.id}.question-${ques.id}.$dirty`, false)
                    ? false
                    : null
                "
                :required="ques.is_required"
              ></b-form-textarea>

              <b-media
                class="align-items-center"
                vertical-align="center"
                v-if="ques.question.type === QUES_TYPES.FILE_TYPE"
              >
                <b-form-file
                  :id="`section-${section.id}-question-${ques.id}`"
                  :placeholder="get(ques.question, 'placeholder', 'Select File')"
                  :browse-text="$t('generalMsgs.browse')"
                  v-model="selectedQueUploadFile[`sec-${section.id}-que-${ques.id}`]"
                  @input="uploadFile('file_type', 'documents', '', section.id, ques.id)"
                  :disabled="isQueFileUploading[`sec-${section.id}-que-${ques.id}`]"
                  :required="ques.is_required && !selectedQueUploadFile[`sec-${section.id}-que-${ques.id}`]"
                />
              </b-media>

              <v-select
                v-if="ques.question.type === QUES_TYPES.SINGLE_SELECT"
                :id="`section-${section.id}-question-${ques.id}`"
                class="form-control v-select-custom"
                label="text"
                item-text="text"
                v-model="customSectionData[`section-${section.id}`][`question-${ques.id}`]"
                :reduce="(ins) => ins.value"
                :placeholder="get(ques.question, 'placeholder', 'Please write your answer here.')"
                :options="get(ques.question, 'extra.options', [])"
                :class="
                  !get($v, `customSectionData.section-${section.id}.question-${ques.id}.required`, true) &&
                  get($v, `customSectionData.section-${section.id}.question-${ques.id}.$dirty`, false)
                    ? 'form-control is-invalid'
                    : ''
                "
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="ques.is_required && !customSectionData[`section-${section.id}`][`question-${ques.id}`]"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
                <template slot="option" slot-scope="option"> {{ option.text }} </template>
                <template slot="selected-option" slot-scope="option">
                  {{ option.text }}
                </template>
              </v-select>
              <v-select
                v-if="ques.question.type === QUES_TYPES.MULTI_SELECT"
                :id="`section-${section.id}question-${ques.id}`"
                class="form-control v-select-custom"
                label="text"
                item-text="text"
                v-model="customSectionData[`section-${section.id}`][`question-${ques.id}`]"
                :reduce="(ins) => ins.value"
                :placeholder="get(ques.question, 'placeholder', 'Please write your answer here.')"
                :options="get(ques.question, 'extra.options', [])"
                :class="
                  !get($v, `customSectionData.section-${section.id}.question-${ques.id}.required`, true) &&
                  get($v, `customSectionData.section-${section.id}.question-${ques.id}.$dirty`, false)
                    ? 'form-control is-invalid'
                    : ''
                "
                multiple
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="
                      ques.is_required &&
                      !get(customSectionData[`section-${section.id}`][`question-${ques.id}`], 'length')
                    "
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
                <template slot="option" slot-scope="option"> {{ option.text }} </template>
                <template slot="selected-option" slot-scope="option">
                  {{ option.text }}
                </template>
              </v-select>
              <b-form-invalid-feedback
                :state="
                  !get($v, `customSectionData.section-${section.id}.question-${ques.id}.required`, true) &&
                  get($v, `customSectionData.section-${section.id}.question-${ques.id}.$dirty`, false)
                    ? false
                    : null
                "
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>
          </div>
        </div>

        <b-btn variant="primary" style="width: 150px" type="submit" class="btn-normal float-right">
          <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>Submit</span>
        </b-btn>
      </b-form>
    </div>

    <!-- Registeration success component -->
    <div class="page-section" v-if="preEnrolledSuccessfull">
      <div :class="containerClass" class="mt-5">
        <div class="page-section d-flex justify-content-center">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-check-circle fa-lg text-success mt-4 mb-4" style="font-size: 3rem" />
              <h4>Enrollment Request Sent!</h4>

              <p class="text-muted">
                You have successfully filled pre-enrollment information and will receive an email to register in the
                program once your verification is completed. For any queries, contact administration.
              </p>
              <a href="#" @click.prevent="goToHome" class="btn btn-primary"
                ><i class="fas fa-home mr-2"></i>Back to Home
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Pre enrollment failure component -->
    <div class="page-section" v-else-if="preEnrolledUnsuccessfull && !showPaymentDetail && !showProducts">
      <div :class="containerClass" class="mt-5">
        <div class="page-section d-flex justify-content-center">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-times-circle fa-lg text-danger mt-4 mb-4" style="font-size: 3rem" />
              <h4>Pre Enrollment Failed!</h4>

              <p class="text-muted">
                Cannot pre enrolled you in this class. Please try again later or contact the administrator.
              </p>
              <a href="#" @click.prevent="goToHome" class="btn btn-primary"
                ><i class="fas fa-home mr-2"></i>Back to Home
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { get, map } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { countries } from 'countries-list';
import generator from 'generate-password';
import Page from '@/components/Page.vue';
import UserAvatar from '@/components/User/UserAvatar.vue';
import DatePicker from 'vue2-datepicker';
import { required, requiredIf, email } from 'vuelidate/lib/validators';
import {
  PHONE_FORMAT_REGEX,
  ETHNICITY_OPTIONS,
  STATIC_FIELD_TYPE,
  QUES_TYPES,
  STD_REG_REASON_OPTIONS,
  YES_NO_OPTIONS,
  GENDER_OPTIONS,
  STD_REG_REFERRAL_OPTIONS,
  STD_REG_FORM_PAY_OPTIONS,
  STD_REG_PAY_OPTIONS,
  CLASS_SCHEDULE_TYPES,
} from '@/common/constants';
import {
  getLangOptions,
  formatDateSimple,
  formatToAPIDate,
  getWorkTravelOptions,
  formatFullDate,
  titleize,
} from '@/common/utils';

var UsaStates = require('usa-states').UsaStates;
import vSelect from 'vue-select';
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  components: {
    UserAvatar,
    DatePicker,
    vSelect,
    ImageUploader,
  },
  extends: Page,
  layout: 'blank',

  data() {
    return {
      ssnPassType: 'password',
      visibility: false,
      title: 'Registration',
      preEnrolledSuccessfull: false,
      preEnrolledUnsuccessfull: false,

      confirmParams: {},
      isLoading: false,
      school: null,
      user: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        repeatPassword: '',
        phone: '',
        bio: '',
        avatarUrl: '',
        gender: '',
      },
      student: {
        birthday: null,
        is_profile_visible: true,

        desired_work_type: [],
        desired_work_time: [],
        desired_work_day: [],
        work_travel: '',
        lang_preferences: ['en'],
        certified_before: false,
        resume_url: '',
        address: '',
        zipcode: '',
        city: '',
        state: '',
        country: 'United States',

        career_service_interest: true,
        student_ssn: '',

        occupation: '',
      },
      ethnicity: '',
      ethnicity_other: '',
      registration_reason: '',
      other_registration_reason: '',
      other_reference: '',
      reference: '',
      signature: '',
      interested_facility_types: [],

      payment_option: STD_REG_PAY_OPTIONS.PAY_NOW,
      sendEmail: true,

      allReferals: STD_REG_REFERRAL_OPTIONS,
      allReasons: STD_REG_REASON_OPTIONS,
      fieldTypes: { password: 'text', repeatPassword: 'text' },
      STD_REG_FORM_PAY_OPTIONS,

      allSchools: [],
      allPrograms: [],
      countryOptions: [],
      stateOptions: [],
      workExpOptions: [
        { value: '0-1', text: '0-1 year' },
        { value: '1-2', text: '1-2 years' },
        { value: '3-4', text: '3-4 years' },
        { value: '5-6', text: '5-6 years' },
        { value: '7+', text: '7+ years' },
      ],
      workDayOptions: [],
      workTypeOptions: [
        { value: 'part_time', text: 'Part-time' },
        { value: 'full_time', text: 'Full-time' },
        { value: 'not_sure', text: 'Not sure' },
      ],
      workTimeOptions: [
        { value: 'day', text: 'Day' },
        { value: 'evening', text: 'Evening' },
        { value: 'overnight', text: 'Overnight' },
      ],
      workTravelOptions: [],

      facilityTypeOptions: [
        { value: 'adult_day_care', text: 'Adult day care' },
        { value: 'assisted_living', text: 'Assisted living' },
        { value: 'hospital', text: 'Hospital' },
        { value: 'hospice_care', text: 'Hospice care' },
        { value: 'home_care', text: 'Home care' },
        { value: 'longterm_care', text: 'Long-term care' },
        { value: 'other', text: 'Other' },
      ],
      careerPreferences: [],
      profileVisOptions: [
        { value: true, text: 'Active' },
        { value: false, text: 'Paused' },
      ],
      langOptions: [],
      yesNoOptions: YES_NO_OPTIONS,
      genderOptions: GENDER_OPTIONS,
      ethnicityOptions: ETHNICITY_OPTIONS,

      errorStates: {
        password: null,
        userEmail: null,
        cnaLicenseNo: null,
        phone: null,
      },
      passErrorCode: null,

      isPassCopied: false,
      areSchoolsLoading: false,
      isLogoUploading: false,
      areCareerPrefsLoading: false,

      isUploading: {
        avatar: false,
        resume: false,
      },
      isQueFileUploading: {},
      selectedUploadFile: {
        avatar: null,
        resume: null,
      },
      selectedQueUploadFile: {},
      uploadPercent: { avatar: 0, resume: 0, file_type: {} },
      uploadCancelTokenSource: {
        avatar: null,
        resume: null,
        file_type: {},
      },
      classSch: {},
      isDataLoading: false,
      isClassLoading: false,
      responseData: {
        lang_preferences: null,
        desired_work_type: null,
        desired_work_time: null,
        desired_work_day: null,
        work_travel: null,
        interested_facility_types: null,
        student_ssn: null,
        reference: null,
        registration_reason: null,
        plan_to_pay: null,
        occupation: null,
        gender: null,
        ethnicity: null,
        address: null,
        zipcode: null,
        city: null,
        state: null,
        country: null,
        resume_url: null,
      },
      file_type: null,
      areSectionsLoading: false,
      STATIC_FIELD_TYPE,
      QUES_TYPES,
      sections: [],
      customSectionData: {},
      customSectionValidation: null,
      STD_REG_PAY_OPTIONS,
      reg_desc: null,
      CLASS_SCHEDULE_TYPES,
    };
  },
  validations() {
    const val = {
      registration_reason: {
        required: requiredIf(() => this.responseData.registration_reason === STATIC_FIELD_TYPE.REQUIRED),
      },

      ethnicity: {
        required: requiredIf(() => get(this.responseData, 'ethnicity') === STATIC_FIELD_TYPE.REQUIRED),
      },
      ethnicity_other: { required: requiredIf(() => this.ethnicity === 'other') },
      other_registration_reason: { required: requiredIf(() => this.registration_reason === 'other') },
      student: {
        birthday: { required },
        certified_before: { required },
        career_service_interest: { required },

        desired_work_type: {
          required: requiredIf(() => this.responseData.desired_work_type === STATIC_FIELD_TYPE.REQUIRED),
        },
        desired_work_time: {
          required: requiredIf(() => this.responseData.desired_work_time === STATIC_FIELD_TYPE.REQUIRED),
        },
        desired_work_day: {
          required: requiredIf(() => this.responseData.desired_work_day === STATIC_FIELD_TYPE.REQUIRED),
        },
        work_travel: { required: requiredIf(() => this.responseData.work_travel === STATIC_FIELD_TYPE.REQUIRED) },

        lang_preferences: {
          required: requiredIf(() => this.responseData.lang_preferences === STATIC_FIELD_TYPE.REQUIRED),
        },
        resume_url: { required: requiredIf(() => this.responseData.resume_url === STATIC_FIELD_TYPE.REQUIRED) },

        address: { required: requiredIf(() => this.responseData.address === STATIC_FIELD_TYPE.REQUIRED) },
        zipcode: { required: requiredIf(() => this.responseData.zipcode === STATIC_FIELD_TYPE.REQUIRED) },
        city: { required: requiredIf(() => this.responseData.city === STATIC_FIELD_TYPE.REQUIRED) },
        state: { required: requiredIf(() => this.responseData.state === STATIC_FIELD_TYPE.REQUIRED) },
        country: { required: requiredIf(() => this.responseData.country === STATIC_FIELD_TYPE.REQUIRED) },
        student_ssn: {
          required: requiredIf(() => this.responseData.student_ssn === STATIC_FIELD_TYPE.REQUIRED),
          length(value) {
            return this.responseData.student_ssn === STATIC_FIELD_TYPE.REQUIRED ? this.isFourDigitSSN ? value.length === 4 : value.length === 9:true;
          },
        },

        occupation: { required: requiredIf(() => this.responseData.occupation === STATIC_FIELD_TYPE.REQUIRED) },
      },

      other_reference: { required: requiredIf(() => this.reference === 'other') },
      reference: { required: requiredIf(() => this.responseData.reference === STATIC_FIELD_TYPE.REQUIRED) },
      interested_facility_types: {
        required: requiredIf(() => this.responseData.interested_facility_types === STATIC_FIELD_TYPE.REQUIRED),
      },

      payment_option: { required: requiredIf(() => get(this.classSch, 'program.tuition_reg_req') === false) },
      user: {
        firstName: { required },
        lastName: { required },
        email: { required, email },
        phone: {
          required,
          isCorrectFormat(value) {
            return PHONE_FORMAT_REGEX.test(value);
          },
        },
        avatarUrl: '',
        gender: { required: requiredIf(() => this.responseData.gender === STATIC_FIELD_TYPE.REQUIRED) },
      },
      customSectionData: {},
    };

    return val;
  },
  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    isFourDigitSSN() {
      return this.getCurrentSchool ? this.getCurrentSchool.is_four_digit_ssn : false;
    },
    isFormLoading() {
      return (
        this.isLoading ||
        this.isClassLoading ||
        this.isLogoUploading ||
        this.isDataLoading ||
        this.isPayLoading ||
        this.areProductsLoading ||
        Object.values(this.isUploading).some((val) => val) ||
        Object.values(this.isQueFileUploading).some((val) => val)
      );
    },

    fullClassLocation() {
      return get(this.classSch, 'class_location')
        ? `${this.classSch.class_location.address}, ${this.classSch.class_location.city}, ${this.classSch.class_location.state} ${this.classSch.class_location.zipcode}, ${this.classSch.class_location.country}`
        : 'N/A';
    },

    enteredStdAddr() {
      const addr = [this.student.address, this.student.city, this.student.state, this.student.zipcode].filter(
        (e) => !!e
      );

      return addr.join(', ');
    },
  },
  watch: {
    classSch: {
      handler(value) {
        if (value && this.getCurrentSchool) {
          this.school = get(this.getCurrentSchool, 'id');
          this.fetchRegistrationForm();
        }
      },
    },
  },
  methods: {
    ...mapActions('student', [
      'getStudentUploadPresignedUrl',
      'createStudent',
      'createRegPaymentIntent',
      'registrationPaymentSuccess',
      'registrationPaymentFailed',
      'getInitiateReg',
    ]),
    ...mapActions('registrationForm', ['getPublicRegistrationForm']),
    ...mapActions('preEnrollForm', ['createPreEnrollmentForm']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('classes', ['getPublicClass']),
    ...mapActions('sections', ['getPublicSections']),
    ...mapActions('payoutAcc', ['intentPay']),
    ...mapActions('products', ['getPublicProducts']),
    ...mapActions('preEnrollForm', ['getPreEnrollFormObj']),
    formatDateSimple,
    formatFullDate,

    get,
    setImage(file, image, uploadType = 'images') {
      this.selectedUploadFile[image] = file;
      this.uploadFile(image, uploadType);
    },
    goToHome() {
      this.$router.push({ name: 'home' });
    },
    async fetchPreEnrollForm() {
      this.isPreEnrollLoading = true;
      try {
        const resp = (await this.getPreEnrollFormObj({ id: this.$route.query.preEnrolled })).data;
        this.user.email = resp.email;
        this.user.firstName = resp.first_name;
        this.user.lastName = resp.last_name;
        this.user.phone = resp.phone;

        this.classIdFromPreEnroll = resp.class_enrolled;
        this.student.birthday = resp.payload;
        this.user.gender = resp.payload.gender;
        this.student.desired_work_type = resp.payload.desired_work_type;
        this.student.desired_work_time = resp.payload.desired_work_time;
        this.student.desired_work_day = resp.payload.desired_work_day;
        this.student.work_travel = resp.payload.work_travel;
        this.student.lang_preferences = resp.payload.lang_preferences;
        this.student.certified_before = resp.payload.certified_before;
        this.student.resume_url = resp.payload.resume_url;
        this.student.address = resp.payload.address;
        this.student.zipcode = resp.payload.zipcode;
        this.student.city = resp.payload.city;
        this.student.state = resp.payload.state;
        this.student.country = resp.payload.country;
        this.interested_facility_types = resp.payload.interested_facility_types;
        this.student.career_service_interest = resp.payload.career_service_interest;
        this.student.student_ssn = resp.payload.student_ssn;
        this.ethnicity = resp.payload.ethnicity;

        this.student.occupation = resp.payload.occupation;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isPreEnrollLoading = false;
    },
    async fetchSections(params = {}) {
      this.areSectionsLoading = true;
      try {
        const res = await this.getPublicSections({
          section_enroll_type: 'pre_enrollment',
          program: this.classSch.program.id,
          ...params,
        });

        const secData = {};
        const queSecUploadFile = {};
        const queFileUploading = {};

        for (const sectionObj of res.data) {
          const quesObj = sectionObj.sectionquestion_set.reduce((obj, item) => {
            if (item.question.type === QUES_TYPES.FILE_TYPE) {
              queSecUploadFile[`sec-${sectionObj.id}-que-${item.id}`] = null;
              queFileUploading[`sec-${sectionObj.id}-que-${item.id}`] = false;
            }

            return Object.assign(obj, {
              [`question-${item.id}`]: null,
            });
          }, {});
          secData[`section-${sectionObj.id}`] = quesObj;
        }

        this.selectedQueUploadFile = queSecUploadFile;
        this.isQueFileUploading = queFileUploading;

        this.customSectionData = secData;
        this.sections = res.data;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areSectionsLoading = false;
    },
    async fetchRegistrationForm() {
      this.isDataLoading = true;
      try {
        const resp = (
          await this.getPublicRegistrationForm({
            params: {
              program: this.classSch.program.id,
              school: this.school,
              enrollment_type: 'pre_enrollment',
            },
          })
        ).data;

        resp.forEach((obj) => {
          this.responseData[obj.field_abbr] = obj.status;
        });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isDataLoading = false;
    },
    async fetchClass() {
      this.isClassLoading = true;

      const response = await this.getPublicClass({ class: this.$route.query.class || this.classIdFromInitiate });
      this.classSch = response.data;

      if (get(this.getCurrentSchool, 'pp_registration_desc')) {
        this.reg_desc = this.getCurrentSchool.pp_registration_desc
          .replaceAll(
            '{{application_fee}}',
            this.$n(get(this.classSch, 'program.application_fee', 0), 'currency', 'en-US')
          )
          .replaceAll('{{tuition_fee}}', this.$n(get(this.classSch, 'program.tuition_fee', 0), 'currency', 'en-US'));
      }
      this.isClassLoading = false;
    },
    backToForm() {
      this.showPaymentDetail = false;
      this.elementsOptions['clientSecret'] = '';

      this.confirmParams['return_url'] = '';
      this.registrationPaymentFailed({ draft_id: this.intentDraftId });
    },
    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();

      if (!this.$v.$invalid) {
        try {
          const sectionsArr = [];
          Object.entries(this.customSectionData).map((section) => {
            Object.entries(section[1]).map((question) => {
              sectionsArr.push({
                section_question: parseInt(question[0].split('-')[1]),
                response: { data: question[1] },
              });
            });
          });
          this.student.birthday = this.student.birthday ? formatToAPIDate(moment(this.student.birthday)) : null;

          const data = {
            school: this.school,

            class_enrolled: this.classSch.id,
            status: 'pending',

            payload: {
              ...this.student,
              reference: this.reference,
              ...(this.other_reference && { other_reference: this.other_reference }),
              ethnicity: this.ethnicity,
              ...(this.ethnicity_other && { ethnicity_other: this.ethnicity_other }),
              interested_facility_types: this.interested_facility_types,
              bio: this.user.bio,
              avatar_url: this.user.avatarUrl,
              gender: this.user.gender,

              ...(this.$route.query.affid && { aff_id: this.$route.query.affid }),
            },
            pre_enroll_response: sectionsArr,

            email: this.user.email,
            first_name: titleize(this.user.firstName),
            last_name: titleize(this.user.lastName),
            password: generator.generate({ length: 10, numbers: true, strict: true }),
            phone: this.user.phone,

            reg_id: this.$route.query.regId || null,
          };

          // Adding payment intent related info for displaying payment element

          await this.createPreEnrollmentForm({ ...data });
          this.preEnrolledSuccessfull = true;
        } catch (err) {
          this.preEnrolledUnsuccessfull = true;
          this.makeToast({
            variant: 'danger',
            msg: 'Cannot register you in this class. Please contact administrator.',
            autoHideDelay: 5000,
          });
          this.isPaymentDetailsLoading = false;
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    },
    changeField() {
      if (this.ssnPassType === 'text') {
        this.ssnPassType = 'password';
        this.visibility = false;
      } else {
        this.ssnPassType = 'text';
        this.visibility = true;
      }
    },
    async uploadFile(
      fileType,
      uploadType = 'documents',
      profileUrlField = 'resume_url',
      section = null,
      question = null
    ) {
      let file;

      if (fileType === QUES_TYPES.FILE_TYPE) {
        this.uploadCancelTokenSource[fileType][`sec-${section}-que-${question}`] = axios.CancelToken.source();
        this.isQueFileUploading[`sec-${section}-que-${question}`] = true;
        file = this.selectedQueUploadFile[`sec-${section}-que-${question}`];
      } else {
        this.uploadCancelTokenSource[fileType] = axios.CancelToken.source();
        this.isUploading[fileType] = true;
        file = this.selectedUploadFile[fileType];
      }

      try {
        const urlResp = await this.getStudentUploadPresignedUrl({
          file_name: file.name,
          content_type: file.type,
          upload_type: uploadType,
        });

        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: file,
          config: {
            onUploadProgress: function (progressEvent) {
              const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              if (fileType === QUES_TYPES.FILE_TYPE) {
                this.uploadPercent[fileType][`sec-${section}-que-${question}`] = percentage;
              } else {
                this.uploadPercent[fileType] = percentage;
              }
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[fileType].token,
          },
        });
        if (fileType === 'avatar') {
          this.user.avatarUrl = urlResp.upload_url.split('?')[0];
        } else if (fileType === 'file_type') {
          this.customSectionData[`section-${section}`][`question-${question}`] = urlResp.upload_url.split('?')[0];
          this.file_type = urlResp.upload_url.split('?')[0];
        } else this.student[profileUrlField] = urlResp.upload_url.split('?')[0];
      } catch (error) {
        if (fileType === QUES_TYPES.FILE_TYPE) {
          this.selectedQueUploadFile[`sec-${section}-que-${question}`] = null;
        } else {
          this.selectedUploadFile[fileType] = null;
        }
      }
      if (fileType === QUES_TYPES.FILE_TYPE) {
        this.uploadCancelTokenSource[fileType][`sec-${section}-que-${question}`] = null;
        this.uploadPercent[fileType][`sec-${section}-que-${question}`] = 0;
        this.isQueFileUploading[`sec-${section}-que-${question}`] = false;
      } else {
        this.uploadCancelTokenSource[fileType] = null;
        this.uploadPercent[fileType] = 0;
        this.isUploading[fileType] = false;
      }
    },
    fillInOptions() {
      var usStates = new UsaStates();
      this.stateOptions = this.stateOptions.concat(
        map(usStates.states, (state) => ({
          value: state.name,
          text: state.name,
        }))
      );
      this.countryOptions = this.countryOptions.concat(
        map(countries, (country) => ({
          value: country.name,
          text: country.name,
        }))
      );

      this.workDayOptions = map(moment.weekdays(), (day) => ({ value: day.toLowerCase(), text: day }));

      this.workTravelOptions = getWorkTravelOptions();

      this.langOptions = getLangOptions();
    },
  },
  async created() {
    this.fillInOptions();

    await this.fetchClass();
    this.fetchSections();
  },
};
</script>

<style>
</style>
